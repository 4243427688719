.nav-link {
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 9999px;
  padding: 0.2rem 1rem;
  text-align: center;
}

.nav-link:hover {
  background-color: #e8b211;
  color: #fff;
}
